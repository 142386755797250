import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import Layout from "../components/Layout"
import Form from "../components/Form/Form"

const Contacto = () => {
  return (
    <Layout>
      <div className="container margin-y-lg max-width-xs">
        <div className="text-component margin-top-lg">
          <h2 className="text-md">Contacto</h2>
          <p>
            Para hacer llegar sus dudas y sugerencias a Viproman S.A., por
            favor, rellene los campos de este formulario y pulse el botón
            "Enviar".
          </p>
        </div>
        <div className="margin-y-sm max-width-xs">
          <GoogleReCaptchaProvider
            reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
            <Form />
          </GoogleReCaptchaProvider>
        </div>
      </div>
    </Layout>
  )
}

export default Contacto
