import React from "react"
import Icon from "../Icon/Icon"

const Input = props => (
  <>
    <label
      htmlFor={props.id}
      className={`form-label margin-bottom-xs ${props.classNameLabel}`}
    >
      {props.label}
    </label>

    <div className="form-control-wrapper form-control-wrapper--icon-{inputIconAlign}">
      <input
        type={props.type}
        placeholder={props.placeholder}
        className={`form-control width-100% ${props.classNameInput}`}
        id={props.id}
        name={props.id}
        disabled={props.isDisabled}
        aria-invalid={props.hasError}
        required={props.required}
        value={props.value}
        onChange={props.onChange}
      />

      {props.inputIcon && (
        <Icon
          iconName={props.inputIcon}
          iconClassName="icon--sm form-control-icon"
        />
      )}

      {props.formErrorMessage && (
        <p className="form-error-msg form-error-msg--is-visible">
          <Icon iconName="icon-notifications-alert-triangle" />
          {props.formErrorMessage}
        </p>
      )}

      {props.formInfoText && (
        <p className="margin-top-xxxs text-xxs color-text-2">
          {props.formInfoText}
        </p>
      )}
    </div>
  </>
)

export default Input
