import React from "react"

import Icon from "../Icon/Icon"

const Button = props => (
  <button
    className={`btn ${props.btnType ? `btn--${props.btnType}` : ""} ${
      props.btnSize ? `btn--${props.btnSize}` : ""
    }
    ${props.btnClassName ? props.btnClassName : ""}`}
    aria-controls={props.btnAriaControlId}
  >
    {props.iconName && <Icon iconName={props.iconName} />}

    {props.btnContent && <span>{props.btnContent}</span>}
  </button>
)

export default Button
